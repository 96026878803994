@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');


body,
html {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #000;
  line-height: 1.4;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1200px;
}

.col-main {
  flex: 0 0 auto;
  width: calc(100% - 380px);
  position: relative;
}

.col-cart {
  flex: 0 0 auto;
  width: 380px;
}

.page {
  padding-top: 80px;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
}

.navi {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 800;
  line-height: 1.2;
}

.btn {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  line-height: 1;
  border-radius: 5px;
  height: 35px;
  transition: all 0.25s ease;
}

.btn:hover {
  color: inherit;
}

.btn-black {
  background: #000;
  color: #fff;
}

.btn-black:hover {
  background: #000;
  color: #fff;
}

.btn-grey {
  background: #838383;
  color: #fff;
}

.btn-dark {
  background: #222222;
  color: #fff;
}

.btn-main {
  background: #77e6ff;
  color: #0e315c;
}

.btn-blue {
  background: #0e315c;
  color: #fff;
}

.btn-blue:hover {
  background: #0e315c;
  color: #fff;
  box-shadow: 0 0 0 0.25rem rgb(119 230 255 / 23%);
}

.btn-blue-reverse {
  background: #fff;
  color: #0e315c;
  border: 1.5px solid #0e315c;
}

.btn-blue-reverse:hover {
  background: #fff;
  color: #0e315c;
  box-shadow: 0 0 0 0.1rem rgba(12, 34, 107, 0.23);
}

.btn-green {
  background: #00bd57;
  color: #fff;
}

.btn-logwith {
  padding: 0;
}

.btn-logwith .wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
}

.col-icon {
  flex: 0 0 auto;
  width: 35px;
  border-right: 1px solid #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-facebook {
  background: #1877f2;
  color: #fff;
}

.btn-facebook:hover {
  background: #115ab9;
  color: #fff;
}

.btn-google {
  background: #efefef;
  color: #000;
}

.btn-google:hover {
  background: #dddddd;
  color: #000;
}

.btn-border {
  background: transparent;
  color: #828282;
  border: 1px solid #828282;
  font-weight: 400;
  text-transform: capitalize;
}

.btn-not-full {
  width: auto;
  min-width: 170px;
}

.btn-number {
  width: 24px;
  height: 24px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.btn-number .material-icons {
  font-size: 16px;
}

.input-group {
  width: 84px;
  padding-right: 1px;
}

.input-group .form-control {
  padding: 0 10px;
  font-size: 14px;
  font-weight: 800;
  line-height: 1;
  background-color: #fff;
  border: 0px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
}

.card .form-control {
  font-weight: 800;
}

.form-control {
  font-size: 14px;
}

.btn-number.plus {
  background: #000;
  color: #fff;
}

.oldPrice {
  clear: both;
  color: #000;
  font-size: 14px;
  line-height: 1;
  text-decoration: line-through;
  font-weight: 600;
}

.font-8 {
  font-size: 8px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-30 {
  font-size: 30px !important;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

.weight-800 {
  font-weight: 800;
}

.weight-900 {
  font-weight: 900 !important;
}

.color-main {
  color: #77e6ff !important;
}

.color-white {
  color: #fff;
}

.color-grey {
  color: #828282;
}

.color-orange {
  color: #ff7a00;
}

.color-green {
  color: #00bd57;
}

.color-black {
  color: #000;
}

.color-blue {
  color: #0e315c;
}

a,
span.payment {
  color: #2d9cdb;
  text-decoration: none;
}

a:hover,
span.payment:hover {
  color: #0a58ca;
}

hr {
  margin: 1rem 0 !important;
  color: #e0e0e0;
  opacity: 1;
}

.lh-1 {
  line-height: 1;
}

.relative {
  position: relative;
}

.or {
  font-size: 10px;
  padding: 5px;
  line-height: 1;
  color: #b4b4b4;
  display: inline-block;
  text-align: center;
  background: #ededed;
  position: absolute;
  left: 50%;
  margin: auto;
  top: 5px;
  transform: translateX(-50%);
}

.main {
  padding-top: 20px;
  padding-bottom: 80px;
}

.left-wrapper {
  max-width: 350px;
  margin-left: auto;
}

/*progressbar*/
.bg-black {
  background-color: #000;
}

.bg-grey {
  background-color: #f9f9f9;
}

.bg-grey2 {
  background-color: #ededed;
}

.section-step {
  padding: 25px 0 0;
}

.navmain {
  line-height: 1;
}

.icon {
  width: 24px;
}

.col-nav {
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  min-width: 62px;
  justify-content: center;
}

.navmain a {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
}

.navmain a.ticket {
  color: #0e315c;
  background-color: #77e7ff;
  height: 54px;
  display: flex;
  align-items: center;
  padding: 20px 38px;
}

.navmain .dropdown-toggle {
  font-size: 10px;
  font-weight: 900;
}

#progressbar {
  margin-bottom: 0px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar {
  margin-bottom: 0px;
  overflow: hidden;
  counter-reset: step;
  padding: 0;
  width: 75%;
  margin: auto;
}

#progressbar li {
  list-style-type: none;
  color: #e0e0e0;
  text-transform: uppercase;
  font-size: 12px;
  width: 20%;
  float: left;
  position: relative;
  letter-spacing: 0;
  text-align: center;
  line-height: 1;
  z-index: 1;
  padding-bottom: 0px;
}

#progressbar .steptitle {
  margin-bottom: 0;
  display: inline-block;
  padding: 0 25px 17px;
  font-weight: 700;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  line-height: 1;
  display: block;
  font-size: 12px;
  color: #e0e0e0;
  background: white;
  border-radius: 50px;
  margin: 0 auto 10px auto;
  text-align: center;
  font-weight: bold;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.step {
  width: 40px;
  height: 40px;
  line-height: 1;
  display: block;
  font-size: 14px;
  color: #e0e0e0;
  border-radius: 50px;
  margin: 5px auto 10px auto;
  text-align: center;
  font-weight: bold;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.done .steptitle {
  font-weight: 800;
  color: #000;
}

.done .step {
  background: #000;
  color: #fff;
  border: 0;
}

.active .step {
  background: #77e6ff;
  color: #0e315c;
  border: 0;
  font-weight: 800;
  border-color: #77e6ff;
  box-shadow: 0 0 0 0.25rem rgb(119 230 255 / 23%);
}

.active .steptitle {
  border-bottom: 2px solid;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: calc(100% - 40px);
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  left: calc(-50% + 20px);
  top: 23px;
  z-index: -1;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  color: #0e315c;
  font-weight: 800;
  border: 0;
}

#progressbar li.done:before {
  font-family: "Material Icons";
  content: "e876";
}

#progressbar li.active {
  font-weight: 800;
  color: #000;
}

.box-border {
  border: 1px solid #e0e0e0;
  padding: 0px;
  border-radius: 0px;
}

.box-body {
  padding: 15px 20px;
}

.box-cart-header {
  background-color: #000;
  color: #fff;
  font-size: 24px;
  padding: 20px 20px;
  position: relative;
}

.box-main-header {
  /* background-color: #77E6FF; */
  /* font-size: 24px; */
  padding: 20px 20px;
  position: relative;
  height: 120px;
}

.box-main-header:before {
  content: "";
  position: absolute;
  background-color: #77e6ff;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.box-main-body {
  padding: 25px 20px;
}

.box-main-body .box-body {
  padding: 20px 20px;
}

.desc {
  font-size: 12px;
  font-weight: 500;
}

.cart-title {
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 1;
}

.cart-title .icon {
  font-size: 24px;
  line-height: 1;
  margin-right: 10px;
}

/* .product-sample {
  position: absolute;
  top: 20px;
  width: 46%;
  height: 230px;
} */

footer {
  padding: 10px 0;
  border-top: 1px solid #000;
}

.border-left {
  border-left: 1px solid #e0e0e06b;
}

.border-right {
  border-right: 1px solid #e0e0e06b;
}

.col-footer {
  height: 107px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.list-social .list-inline-item:not(:last-child) {
  margin-right: 20px;
}

.list-footer-menu {
  font-size: 8px;
}

.list-footer-menu a {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.list-footer-menu a:hover {
  text-decoration: underline;
}

.main-title {
  font-weight: 800;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 0px;
  margin-left: -2px;
  /* padding-top: 14px; */
}

.box-main ul {
  padding-left: 17px;
}

.afterimage {
  padding-top: 133px;
  min-height: 280px;
}

.box-plus-header {
  background-color: #2dcdf1;
  padding: 20px 20px;
  position: relative;
  color: #fff;
}

.bubble {
  font-weight: 900;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #0e315c;
  width: 156px;
  height: 45px;
  background: #77e6ff;
  border-radius: 10px 10px 10px 0px;
  justify-content: center;
}

.indicator {
  /*position: fixed; */
  /* left: 45px; */
  background: #fff;
  position: absolute;
  /* left: -12%; */
  left: 10px;
  z-index: 1;
  top: 5vh;
  width: 75px;
}

.indicator .nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  color: #828282;
  text-align: center;
}

.indicator .nav-pills .nav-link.active {
  color: #000;
  font-weight: 900;
}

.indicator li:not(:last-child) {
  position: relative;
  background: #fff;
  margin-bottom: 48px;
}

.indicator li:not(:last-child):after {
  background-color: #bbb;
  bottom: 0;
  content: " ";
  /* display: block; */
  height: 72px;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: -1;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  height: 113vh;
  /* min-height: 700px; */
  width: 100%;
  /* padding-top: 20px; */
  padding-right: 20px;
}

.scrollable::-webkit-scrollbar-track {
  /* background-color: #fbfbfb; */
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollable::-webkit-scrollbar {
  width: 3px;

}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bbb;
}

h4.type {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
  text-transform: uppercase;
  display: flex;
  position: relative;
}

h4.type span {
  padding-right: 5px;
  background: #fff;
}

h4.type:after {
  content: "";
  width: 100%;
  height: 1px;
  border-top: 1px solid #e0e0e0;
  position: absolute;
  top: 5px;
  z-index: -1;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px;
  width: 100%;
}

.card.active,
.card-flip.active {
  border: 2px solid #00bd57;
}

.card-title {
  font-size: 18px;
}

.card-footer {
  padding: 0rem 1rem 1.5rem;
  background-color: #fff;
  border-top: none;
}

.card-img-top {
  height: 128px;
  width: 100%;
  object-fit: fill;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.section-1 {
  /* background: url(../img/bg-hot-deals.jpg) no-repeat; */
  /* background: url(../img/water.jpg) no-repeat; */
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.card-home .card-img-top {
  min-height: 180px;
  max-height: 180px;
  width: 100%;
  object-fit: fill;
}

.big-title {
  font-weight: 700;
  font-size: 70px;
  line-height: 1;
  text-transform: uppercase;
}

.section-1 .big-title {
  /* color: #77e6ff; */
  color: black;
  text-align: center;
}

.section-2 {
  /* background: url(../img/bg-super-saver.jpg) no-repeat; */
  /* background: url(../img/water.jpg) no-repeat; */
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}

.section-2 .big-title {
  color: black;
  text-align: center;
}

.card ul {
  padding-left: 17px;
  font-weight: 700;
  font-size: 13px;
  color: #222;
}

.section-3 .big-title {
  color: #000;
  text-align: center;
}

.secondary-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
  text-transform: uppercase;
  color: #000;
}

.bubble.bubble-small {
  width: 62px;
  height: 44px;
  line-height: 1;
}

.pass-wrapper {
  padding: 10% 10%;
}

.boxprice {
  padding: 17px 25px;
}

.boxprice p {
  line-height: 1;
}

.extreme .border {
  border-color: #77e6ff !important;
}

.extreme hr {
  color: #77e6ff !important;
}

.card {
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card .front,
.card .back {
  height: inherit;
  width: inherit;
  overflow: hidden;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.card .front:hover,
.card .back:hover {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
}

/* .card .front .flipButton,
.card .back .flipButton {
} */
.card .front span svg,
.card .back span svg {
  fill: #2c3e50;
}

.card .front {
  z-index: 2;
}

.card .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card.flip {
  /* CSS to make element flip on click */
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.form-wrapper {
  width: 470px;
}

ul.list-payment {
  padding-left: 0px;
}

.list-payment li {
  width: 33%;
  display: inline-block;
  padding-bottom: 15px;
}

.slider {
  margin: 0 auto 0px;
  /* overflow: hidden; */
  /* Item */
  /* Add padding to slick list */
}

.slider .slick-list {
  padding: 0 0% 0 0;
}

.slick-track {
  display: flex;
  min-width: 100%;
}

#slider_super_saver .slick-slide {
  height: auto;
}

.slider-item .card {
  /* opacity: .5; */
  transition: all 0.25s ease;
}

.slick-prev,
.slick-next {
  z-index: 10;
}

.slick-prev:before {
  content: url(../img/slick-left.svg) !important;
}

.slick-next:before {
  content: url(../img/slick-next.svg) !important;
}

.text-desc,
.section-6 .text-desc {
  font-size: 20px;
}

#scroll {
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  display: none;
}

.mini-cart {
  display: none;
}

.border-right-separator {
  border-right: 1px solid #e0e0e06b;
}

.height-full {
  /* height: calc(100vh - 262px); */
  min-height: 500px;
}

@media (max-width: 767.98px) {

  .col-main,
  .col-cart {
    width: 100%;
  }

  .container {
    padding: 0 15px;
  }

  #progressbar {
    width: 95%;
  }

  .step {
    width: 25px;
    height: 25px;
    margin: 5px auto 5px auto;
    font-size: 10px;
  }

  #progressbar li {
    font-size: 8px;
  }

  #progressbar .steptitle {
    padding: 0 5px 0px;
    height: 21px;
  }

  #progressbar li:after {
    content: "";
    width: calc(100% - 25px);
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    left: calc(-50% + 12.5px);
    top: 17px;
    z-index: -1;
  }

  #progressbar li.done:after {
    background: #000;
  }

  .section-step {
    padding: 15px 0 0;
  }

  .font-30 {
    font-size: 20px !important;
  }

  .main {
    padding-top: 20px;
    padding-bottom: 80px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .left-wrapper {
    max-width: 100%;
    margin-left: auto;
    padding: 0 15px 15px;
  }

  .navi .logo {
    /* height: 29px; */
    width: auto;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background: #fefefe;
    border-top: 1px solid #e0e0e06b;
  }

  .page {
    padding-top: 52px;
  }

  .big-title,
  .secondary-title {
    font-size: 30px;
  }

  .slider .slick-list {
    padding: 0 25% 0 0;
  }

  .slider {
    margin: 0 -15px 0px;
    overflow: hidden;
  }

  .slider-item .card,
  .slick-slide .card {
    opacity: 1;
    transition: all 0.25s ease;
  }

  .slider-item.slick-current .card,
  .slick-slide.slick-current .card {
    opacity: 1;
  }

  .rightside {
    display: flex;
    flex-direction: column;
  }

  .card-horizontal {
    height: 100%;
  }

  .text-desc,
  .section-6 .text-desc {
    font-size: 14px;
  }

  .card .font-24 {
    font-size: 20px !important;
  }

  .page-steps {
    padding-bottom: 80px;
  }

  .page-steps.page-checkout {
    padding-bottom: 0px;
  }

  .page-steps .main {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .page-steps .main>.container {
    padding-left: 0;
    padding-right: 0;
  }

  .page-steps .main>.container .row,
  .col-cart .box-body .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col-main,
  .col-cart {
    padding-left: 0;
    padding-right: 0;
  }

  .col-cart {
    right: -100%;
    height: 100%;
    position: fixed;
    width: 100%;
    background: #fff;
    top: 0;
    z-index: 1040;
    overflow-y: auto;
  }

  body {
    position: relative;
    padding-bottom: 0px;
  }

  .close-cart {
    width: 52px;
    height: 52px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  .box-main-body .box-body {
    padding: 15px 5px;
  }

  .box-main-header {
    background-color: #77e6ff;
    padding: 24px 20px;
    /* height: auto; */
  }

  .main-title {
    font-size: 20px;
  }

  .box-main-body {
    padding: 15px 5px;
  }

  .box-body {
    padding: 10px 0px;
  }

  #scroll {
    bottom: 90px;
  }

  .mini-cart {
    height: 80px;
    position: fixed;
    z-index: 1035;
    bottom: 0;
    background: #fff;
    left: 0;
    width: 100%;
    display: block;
    box-shadow: 0px -1px 4px rgb(0 0 0 / 10%);
  }

  .btn {
    font-size: 14px;
    font-weight: 800;
    height: 42px;
  }

  .mini-wrapper {
    padding: 7px 20px;
  }

  .bg-plus-1 {
    background-color: #f2f2f2;
  }

  .afterimage {
    padding-top: 0;
    min-height: unset;
  }

  .list-terms li {
    font-weight: 400;
  }

  .input-group {
    width: 114px;
    padding-right: 1px;
  }

  .input-group .btn {
    position: relative;
    z-index: 2;
    height: 35px;
    width: 35px;
  }

  .bubble {
    font-size: 12px;
    width: 106px;
    margin-left: auto;
  }

  .icon {
    width: 20px;
  }

  .box-cart-header {
    font-size: 20px;
    padding: 15px 11px;
  }

  .form-control {
    font-size: 14px;
    height: 42px;
  }

  .scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 203px);
    min-height: 464px;
    width: calc(100% - 85px);
    padding-top: 20px;
    padding-right: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-top: 0;
    background: #f9f9f9;
  }

  .indicator {
    background: #fff;
    position: absolute;
    left: 0;
    z-index: 1;
    top: 10vh;
    width: 75px;
  }

  .scrollable .card {
    width: 100%;
    margin: 0 auto;
    min-height: 450px;
  }

  .page-steps.page-content-scroll .main {
    padding-bottom: 30px;
  }

  .indicator {
    background: #fff;
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    width: 100%;
    border-bottom: 1px solid #dee2e6;
  }

  .scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 203px);
    min-height: 464px;
    width: calc(100% - 85px);
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-top: 0;
    background: #fff;
    width: 100%;
  }

  .indicator .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    color: #000;
    text-align: center;
    padding: 0px 15px;
    transition: all 0.1s ease;
    line-height: 1.2;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid transparent;
  }

  .indicator .nav-pills .nav-link.active {
    color: #77e6ff;
    font-weight: 900;
    border-bottom: 2px solid;
  }

  .indicator li:not(:last-child) {
    position: relative;
    background: #fff;
    margin-bottom: 0;
  }

  /* .indicator li {
    width: 33%;
  } */
  .indicator li:not(:last-child):after {
    display: none;
  }

  .border-right-separator {
    border-right: 0px solid #e0e0e06b;
  }

  .orbig {
    font-size: 14px;
    padding: 5px;
    line-height: 1;
    color: #000000;
    display: inline-block;
    text-align: center;
    background: #ededed;
    position: absolute;
    left: 50%;
    margin: auto;
    top: 3px;
    transform: translateX(-50%);
    font-weight: 700;
    text-transform: uppercase;
  }

  .list-payment li {
    width: 49%;
    display: inline-block;
    padding-bottom: 20px;
  }
}


/* Slide Video*/

.slidervideo .slick-slide {
  display: inline-flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-radius: 0px;
  padding: 0;
  margin: 0;
  position: relative;
}

.slidervideo {
  width: 100%;
  margin: 0;
}

.slidervideo .slick-prev {
  left: 10px;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slidervideo .slick-next {
  right: 10px;
}

.section-hottest {
  /* background: url(../img/water.jpg) no-repeat; */
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;

}

/* BottomPopup.css */
@media screen and (max-width: 767.98px) {
  .bottom-popup {
    padding: 20px 20px !important;
    font-size: 14px !important;
  }
}

@media (min-width: 767.98px) and (max-width: 1200px) {
  .bottom-popup {
    padding: 40px 40px !important;
  }
}

.bottom-popup {
  background: #003573;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 30px 160px;
  z-index: 1111;
  font-size: 18px;
  text-align: center;
  box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.35);
  transform: translateY(100%);
  /* Start hidden off-screen */
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.bottom-popup.open {
  transform: translateY(0);
  /* Slide in from the bottom */
  opacity: 1;
}

.text-description-white {
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 500;
}

.button-close-popup {
  cursor: pointer;
  display: inline-block;
  padding: 9px 30px 7px;
  font-weight: 700;
  color: #003573;
  background: white;
  border-radius: 50px;
}
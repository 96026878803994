.cookies-popup {
  min-width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  background-color: #fff;
  padding: 40px 35px;
  z-index: 1040;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.cookies-popup.inactive {
  height: 0px;
  padding: 0px 35px;
}

.cookies-table tr td {
  border: 1px solid #000;
  text-align: center;
}

.cookies-table tr th {
  border: 1px solid #000;
  text-align: center;
}

.submenu {
  display: none;
}

.spinner {
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.834);
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.companymenuli:hover>ul {
  display: block;
}

ul .offerings {
  list-style-type: none;
  padding-left: 0;
}

.box-logout {
  left: -251px !important;
  width: 25em;
  text-align: center;
  padding: 2em;
}

.btn-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.logosignin {
  margin-bottom: 3em;
  text-align: center;
}

.btn-lb,
.btn-lb.disabled,
.btn-lb:disabled {
  /* background-image: linear-gradient(#530b79, #6c0650); */
  background-color: #77E6FF;
  border: none;
  color: #000;
  text-decoration: none;
  height: 40px !important;
}

.btn-lb:hover,
.btn-lb:focus-visible,
.btn-lb:focus,
.btn-lb:active,
.btn-lb:active:focus {
  background-color: #77E6FF;
  color: #000;
  border: none;
  box-shadow: none;
}

.btn-db,
.btn-db.disabled,
.btn-db:disabled {
  background-color: #0e315c;
  border: none;
  color: #fff;
  text-decoration: none;
  height: 40px !important;
}

.btn-db:hover,
.btn-db:focus-visible,
.btn-db:focus,
.btn-db:active,
.btn-db:active:focus {
  background-color: #0e315c;
  color: #fff;
  border: none;
  box-shadow: none;
}

.text-signin,
.text-color {
  background-image: linear-gradient(to right, #530b79 0%, #6c0650 100%);
  background-color: #530b79;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-popup-title {
  color: #0e315c;
  color: #0e315c;
  font-weight: 500;
  font-size: 23px;
}

.text-db {
  color: #0e315c;
}

.link-back {
  color: #000;
  text-decoration: underline;
  font-weight: 600;
}

.text-back:hover {
  color: #77E6FF;
}

.link-lb {
  color: #2d9cdb;
  font-weight: 600;
  text-decoration: underline;
}

.link-lb:hover {
  color: #0e315c;
}

.btnloginsocial {
  background-color: #fff;
  border: none;
  color: #000;
  text-decoration: none;
  height: 40px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.btnloginsocial:hover {
  background: #e8e8e8;
}

.spanpadding {
  padding-left: 1rem;
}

.box-signin {
  padding: 3% 4%;
}

.box-text-signin {
  margin: 3em 0;
}

#logosignin {
  width: 20%;
}

.signinradius {
  border-radius: 0.25rem;
}

.text-capitalize {
  text-transform: capitalize;
}

.box-profile {
  padding: 2.5rem;
  border-radius: 0.25rem;
}

.profilepreview {
  width: 15em;
  height: 15em;
  object-fit: cover;
  border-radius: 50%;
  background-color: #e9ecef;
}

#upload-photo {
  display: none;
}

.box-objet-picupload {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.objet-picupload {
  border: none;
  background: transparent;
}

.boxInputPass {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.boxborderPass {
  border-radius: 0 0.25rem 0.25rem 0;
  background-color: #ced4da;
}

.boxborderPass {
  border: 1px solid #ced4da !important;
  font-weight: 400 !important;
  text-align: left !important;
  padding: 5px 10px !important;
  display: flex;
  font-size: 18px;
}

.text-danger {
  margin-top: .5em;
  display: block;
}

/* POPUP STYLE */

.box-terms-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 70%);
  z-index: 1500;

}

.box-terms {
  display: block;
  justify-content: start;
  background-color: #fff;
  padding: 2em;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 600px;
  margin-top: 8%;
}

.react-responsive-modal-root {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 1500 !important;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  margin: 1.75rem auto;
}

.react-responsive-modal-modal {
  border-radius: 0.3rem;
  padding: 3em !important;
  margin: 0 !important;
  align-items: start;
  position: inherit !important;
}

table {
  margin-bottom: 1em;
}

/* .done table {
  margin-bottom: 1em;
  width: 100% !important;
} */

.done table {
  margin-bottom: 1em;
  width: 100% !important;
  line-break: anywhere;
}

.done table tr td {
  padding-right: 0px !important;
}



.done table tr td span {
  margin-bottom: 1em;
  width: 100% !important;
  line-break: anywhere;
  font-size: 16px !important;
  padding-left: unset !important;
}

.done table tr td div {
  margin-bottom: 1em;
  margin-left: 0px !important;
  line-break: anywhere;
  font-size: 16px !important;
}



.react-responsive-modal-closeButton {
  display: none !important;
}

/********* MODAL STYLE (POPUP) **********/
.modal-content,
.cart-savecart .modal-content,
.popup-setpw .modal-content,
.popup-changepw .modal-content,
.popup-forget .modal-content {
  padding: 3em;
  border: none;
  margin: 0 auto;
  overflow: auto;
  -webkit-box-shadow: 5px 5px 9px 0px rgba(20, 20, 20, 1);
  -moz-box-shadow: 5px 5px 9px 0px rgba(20, 20, 20, 1);
  box-shadow: 5px 5px 9px 0px rgba(20, 20, 20, 1);
}

.cart-placeorder .modal-content {
  height: 300px;
  padding: 2em;
}

.cart-placeorder .modal-content .close {
  position: relative;
  top: -20%;
}

.cart-placeorder .modal-content .close-payment {
  position: relative;
  top: -37%;
  text-align: right;
}

.modal-content {
  height: 90vh;
}

.cart-savecart .modal-content,
.popup-changepw .modal-content {
  height: 565px;
  padding: 2em;
}

.popup-setpw .modal-content,
.popup-forget .modal-content {
  height: 628px;
  padding: 2em;
}

.logo-popup {
  width: 40%;
}

.modal-dialog {
  max-width: 550px;
}

.popupterm .modal-dialog {
  max-width: 55%;
}

.cart-savecart .modal-dialog,
.cart-placeorder .modal-dialog,
.popup-setpw .modal-content,
.popup-changepw .modal-content,
.popup-forget .modal-content {
  max-width: 550px;
  width: 550px;
}


/* .cart-placeorder .modal-content {
    justify-content: center;
  }

  .delete-Item .modal-content {
    justify-content: center;
  } */

.modal-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.modal-content::-webkit-scrollbar {
  width: 3px;
}

.modal-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bbb;
}

/* .modal-dialog {
    max-width: 55%;
    border: none;
  } */

.modal {
  display: flex !important;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.close {
  background-color: transparent !important;
  border: none !important;
  text-align: right;
}

/* POPUP STYLE */

li {
  list-style-type: none;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  /* background-color: rgba(0, 0, 0, 0.2); */
  box-shadow: rgba(0, 0, 0, 0.24);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: white;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.text-right {
  text-align: right;
  color: #000;
}

.text-left {
  text-align: left;
  color: #000;
}

.box-passcode {
  font-size: 30px;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: #530b79;
  background-image: -moz-linear-gradient (to right, #530b79 0%, #6c0650 100%);
  background-image: -webkit-gradient (to right, #530b79 0%, #6c0650 100%);
  background-image: -webkit-linear-gradient (to right, #530b79 0%, #6c0650 100%);
  background-image: -o-linear-gradient (to right, #530b79 0%, #6c0650 100%);
  background-image: -ms-linear-gradient (to right, #530b79 0%, #6c0650 100%);
  background-image: linear-gradient (to right, #530b79 0%, #6c0650 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( to right, #530b79 0%, #6c0650 100%); */
  color: #000;
}

.btn-none {
  background: transparent;
  border: none;
}

.defaultPinpad {
  width: 1.5em;
  height: 1.5em;
  background-color: #000;
  border-radius: 50%;
}

.showPinpad {
  width: 2em;
  height: 2em;
  /* background: -webkit-linear-gradient(#530b79, #6c0650); */
  background-color: #77E6FF;
  border-radius: 50%;
  /* box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 10%); */
}

.PinpadPadding {
  padding: 0 5px;
}

.box-dec-promotion {
  min-height: 150px;
}

.box-supersaver-promotion,
.box-earlybird-promotion {
  min-height: 220px;
  max-height: 220px;
}

.box-dec-extra {
  min-height: 110px;
  max-height: 110px;
}

.btn-dark:hover {
  color: #fff;
}

.MuiButton-textPrimary:hover {
  background-color: transparent !important;
}

.size-refresh {
  height: 44px;
  margin: 0 auto;
}

.profilepic {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  object-fit: cover;
}

/* .signinpage .modal-dialog {
  max-width: 55%;
} */

.TermIcon {
  width: 14px;
  height: 14px;
  margin-right: .5rem;
}

.slider-item,
.slick-slide {
  display: inline-flex;
  width: 100%;
  /* height: 450px; */
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-radius: 6px;
  padding: 10px 5px;

}

.payment-2c2p {
  width: 30%;
  /* margin-top: -15px; */
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.ItemGroup {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-blue-nextbuttom {
  background: #0e315c;
  color: #fff;
}


.card-flip {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  position: relative;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card-flip .front:hover,
.card-flip .back:hover {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

/* .card-flip.focus {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
} */

.slick-next {
  /* right: -15px !important; */
  right: -25px !important;
}

.slick-prev {
  left: -25px !important;
}

.btn-explore {
  width: auto;
  min-width: 170px;
}

.none-boder {
  border: none;
}

.back .card-body {
  height: 300px;
}

.box-checkpayment {
  padding: 0 0 0 2.5em;
  /* border: 1px solid rgba(0,0,0,.125); */
  /* box-shadow: -1px 0px 14px -7px rgba(0,0,0,.125); */
  margin-bottom: 1rem;
  border-radius: 5px;
}

.box-payment {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #000;
  border-radius: 5px;
  width: 100%;
}

.cart-mobile {
  display: none;
  padding: 0 2em;
}

.cart-scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  height: 200px;
  min-height: 220px;
  width: 100%;
}

/* .cart-scrollable::-webkit-scrollbar-track {
  background-color: #ff0000;
}

.cart-scrollable::-webkit-scrollbar {
  width: 3px;
  background-color: #ff0000;
}

.cart-scrollable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #f5f5f5;
} */

.cart-scrollable::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.cart-scrollable::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 10px;
}

.box-qrcode {
  min-height: 76vh;
  margin-top: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-payment {
  height: 68vh;
}

.card-horizontal .card-img {
  height: 250px;
  width: 100%;
  object-fit: fill;
}

.boxborderPass:disabled {
  background: #f1f1f1;
}

.profilepic-navber {
  width: 6em;
  height: 6em;
  border-radius: 50%;
  object-fit: cover;
}

.btn-changepw,
.btn-logout {
  padding: 0.5rem;
  font-size: 9px;
  height: auto;
}


.form-check-input[type=checkbox] {
  border-radius: 0.25em;
  padding: 0.70em;
}

.form-check-input:checked[type=checkbox] {
  background-image: url(../img/check.png);
  font-size: 14px;
  /* margin: 0; */
}

.form-check-input:checked {
  background-color: transparent;
  border-color: #e0e0e0;
}

.form-check-input:focus {
  box-shadow: none;
}

.popup-close {
  width: 25px;
  align-self: flex-end;
}

.img-popup {
  width: 85%;
  height: 260px;
  object-fit: contain;
}

.form-check-input:checked[type=radio] {
  background-image: linear-gradient(#530b79, #6c0650);
}

.img-forgetpopup {
  width: 52%;
}

.BackToTop {
  background-color: #333333;
  border: #333333;
  padding: 0 1rem;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fWohKN {
  right: 10px !important;
  bottom: 10px !important;
  margin: 0 !important;
  z-index: 1500;
}

.icon-button-badge {
  position: absolute;
  font-size: 10px;
  width: 18px;
  height: 18px;
  background: red;
  color: #ffffff;
  border-radius: 50%;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  margin-left: -5px;
  margin-top: -5px;
}

.paymen-text {
  text-align: right;
}

.home-card-footer {
  height: 115px;
  max-height: 115px;
  padding: 0rem 1rem 1.5rem;
  background-color: #fff;
  border-top: none
}

.sticky-top {
  top: 75px;
  z-index: 1029;
}

.sticky-top-Item {
  position: -webkit-sticky;
  position: sticky;
  top: 186px;
  /* top: 300px; */
  z-index: 1019;
  background-color: #fff;
}

.sticky-Item {
  position: -webkit-sticky;
  position: sticky;
  top: 235px;
  z-index: 1018;
  background-color: #fff;
}

.required {
  border: 1px solid red;
}

.fixed-step {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.FlexDiv {
  display: flex;
  width: 100%;
}

.LeftColumn {
  display: flex;
  flex-direction: column;
  position: fixed;
  /* left: 0; */
  top: 225px;
  z-index: 1026;
}

.RightColumn {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 113vh;
  overflow-x: hidden;
}

.RightColumn::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.RightColumn::-webkit-scrollbar {
  width: 3px;
}

.RightColumn::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bbb;
}

.StyledElement {
  padding: 20px 20px 0 20px;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  color: #828282;
  text-align: center;
}

.nav-pills .nav-link.active {
  color: #000;
  background: none;
}

.box-space {
  padding: 190px;
}

.box-dec-step {
  position: fixed;
  width: 50%;
  top: 192px;
  background-color: #fff;
  z-index: 1027;
}

.product-sample {
  /* position: absolute; */
  object-fit: fill;
  /* top: -38px;
  width: 46%;
  height: 230px; */
}

.sticky-top {
  z-index: 1028;
}

.ms-auto-cart {
  margin-left: 0;
}

.hidden {
  visibility: hidden;
}

.Itemcard-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.Itemcard-footer {
  padding: 0rem 1rem 1.5rem;
  background-color: #fff;
  border-top: none;
  border-radius: 5px;
}

/* .no-spin { */
input[type=number] {
  -moz-appearance: textfield;
}

.input-qty {
  margin-left: -3px;
}

/* .no-spin::-webkit-outer-spin-button,
.no-spin::-webkit-inner-spin-button { */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.box-mh {
  min-height: 76vh;
}

.gustloginFB .btnloginsocial {
  background-color: #0d6efd;
  color: #fff;
  border: none;
  text-decoration: none;
  height: 40px !important;
  box-shadow: none;
}

.gustloginGG .btnloginsocial {
  background-color: #e8e8e8;
  color: #000;
  border: none;
  text-decoration: none;
  height: 40px !important;
  box-shadow: none;
}

.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
}

.picloginsocial {
  padding-right: 10px;
}

.gustloginGG .picloginsocial {
  padding: 0.7rem 1rem 0.9rem 1rem;
  border-right: 1px solid #fff;
}

.gustloginGG .textloginsocial {
  padding: 0 30%;
}

.gustloginGG .btnloginsocial-guest {
  text-align: left;
  padding: 0;
}

.gustloginFB .picloginsocial {
  padding: 0.6rem 1rem 0.6rem 1rem;
  border-right: 1px solid #fff;
}

.gustloginFB .textloginsocial {
  padding: 0 28%;
}

.gustloginFB .btnloginsocial-guest {
  text-align: left;
  padding: 0;
}

.slider-control-bottomcenter {
  display: none;
}

.align-items-stretch {
  height: 419px;
}

/* .step-3 .align-items-stretch {
  height: 400px;
} */

.front,
.back {
  height: 418px;
}

.back .card-body {
  height: 316px;
}

.Itemcard-body {
  height: 135px;
}

.front .card-footer {
  height: 175px;
}

.landingpage .align-items-stretch {
  height: 475px;
}

.landingpage-small .align-items-stretch {
  height: 303px;
}

.extra-landingpage .align-items-stretch {
  height: 419px;
}

.back .card-footer {
  padding: 0rem 0.8rem 1.5rem;
  width: 99%;
  margin: auto;

}

.btn-done {
  width: auto;
  min-width: 25%;
}

.headsubticket {
  height: 100%;
}

.logo-announce {
  text-align: center;
  height: 85px;
}

.announce-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  justify-content: center;
  margin-bottom: 2em;
}

/* .popup-announce{
  position: fixed;
  top: 25%;
  left: 6%;
  background: white;
  width: 70vw;
  border: none;
  margin: 0 auto;
  overflow: auto;
  box-shadow: 5px 5px 9px 0 #141414;
  padding: 20px;
} */

.popup-announce .modal-content {
  padding: 0em 3em 3em 3em;
}

.announce-close {
  display: flex;
  justify-content: end;
  z-index: 100;
  height: 60px;
  align-items: end;
}

.inactive {
  pointer-events: none;
  background: #e5e5e5;
  color: #c1c1c1;
}

.cursor-pointer {
  cursor: pointer;
}

/* MD */
@media (min-width: 576px) and (max-width: 767.98px) {
  .box-terms {
    margin: 20% 10px 10%;
    max-height: 100%;
  }

  #logosignin {
    width: 50%;
  }

  .box-profile {
    padding: .5rem;
  }

  .box-logout {
    width: 20em;
    padding: 1em;
  }

  .slick-next {
    right: 0px !important;
  }

  .slick-prev {
    left: 0px !important;
  }

  .box-supersaver-promotion,
  .box-earlybird-promotion {
    min-height: 220px;
    max-height: 220px;
  }

  .box-addons {
    min-height: 220px;
    max-height: 220px;
  }

  .box-logout {
    left: -120px !important;
    padding: 2em;
  }

  /* .signinpage .modal-dialog {
    max-width: 100%;
  } */
  .box-dec-extra {
    min-height: 145px;
    max-height: 145px;
  }

  .payment-2c2p {
    width: 35%;
    /* margin-top: -15px; */
  }

  .box-checkpayment {
    padding: 0 0 0 1.5em;
  }

  .ItemGroup {
    height: 4em;
  }

  .slick-next {
    right: 5px !important;
  }

  .slick-prev {
    left: -5px !important;
  }

  .back .card-body {
    height: 301px;
  }

  .card-payment {
    height: 100vh;
  }

  .modal-content {
    padding: 2em;
    height: 60vh;
    width: 95%;
  }

  .popupterm .modal-dialog {
    max-width: 95%;
  }

  .fWohKN {
    bottom: 115px !important;
  }

  .paymen-text {
    text-align: left;
  }

  .sticky-top {
    top: 50px;
  }

  .sticky-top-Item {
    top: 136px;
  }

  .StyledElement {
    padding: 135px 20px 0 20px !important;
  }

  .product-sample {
    width: 44%;
    height: 190px;
  }

}

/* MD*/

/* LG */
@media (min-width: 768px) and (max-width: 991.98px) {
  .box-terms {
    margin: 15% 10px;
    max-height: 100%;
  }

  .slick-next {
    right: 15px;
  }

  .section-3 {
    background-position: center;
    background-size: cover;
    background-attachment: inherit;
  }

  .slick-prev {
    left: 10px;
  }

  .box-supersaver-promotion,
  .box-earlybird-promotion {
    min-height: 220px;
    max-height: 220px;
  }

  .box-addons {
    min-height: 200px;
    max-height: 200px;
  }

  .box-logout {
    left: -251px !important;
  }

  /* .signinpage .modal-dialog {
    max-width: 80%;
  } */
  .box-dec-extra {
    min-height: 150px;
    max-height: 150px;
  }

  .payment-2c2p {
    width: 75%;
    /* margin-top: -10px; */
  }

  .ItemGroup {
    height: 5em;
    align-items: center;
  }

  .indicator .nav-pills .nav-link.active {
    color: #77e6ff;
    font-weight: 900;
    border-bottom: 2px solid;
  }

  .indicator .nav-pills .nav-link {
    padding: 1.5em;
  }

  .slick-next {
    right: 5px !important;
  }

  .slick-prev {
    left: -5px !important;
  }

  .back .card-body {
    height: 283px;
  }

  .scrollable {
    height: 60vh;
  }

  .box-qrcode {
    min-height: 79vh;
    margin-top: -4px;
  }

  .modal-content {
    height: 60vh;
  }

  .cart-placeorder .modal-content .close {
    top: -29%;
  }

  .cart-savecart .modal-content {
    height: 575px;
  }

  .popup-changepw .modal-content {
    height: 565px;
  }

  .popup-setpw .modal-content,
  .popup-forget .modal-content {
    height: 640px;
  }

  #logosignin {
    width: 25%;
  }

  .sticky-top {
    top: 67px;
  }

  .sticky-top-Item {
    top: 176px;
  }

  #progressbar {
    width: 95%;
  }

  /* .StyledElement {
    padding: 50px 20px 0 20px !important;
  } */

  .LeftColumn {
    top: 178px;
    width: 52%;
  }

  /* .RightColumn {
    height: 93vh;
  } */

  .product-sample {
    width: 45%;
    height: 225px;
  }

}

/* LG */

/* XL */
@media (min-width: 992px) and (max-width: 1199.98px) {

  .payment-2c2p {
    width: 55%;
  }

  .scrollable {
    height: 62vh;
    padding-left: 5em;
  }

  .indicator {
    left: 0;
  }

  .btn-not-full {
    min-width: 100%;
  }

  .slick-next {
    right: -25px !important;
  }

  .slick-prev {
    left: -25px !important;
  }

  .back .card-body {
    height: 327px;
  }

  .box-qrcode {
    min-height: 75vh;
    margin-top: -3px;
  }

  .card-payment {
    height: 72vh;
  }

  .modal-dialog {
    max-width: 650px;
  }

  .modal-content {
    height: 80vh;
  }

  .popupterm .modal-dialog {
    max-width: 85%;
  }

  #logosignin {
    width: 25%;
  }

  .sticky-top-Item {
    top: 185px;
  }

  .indicator {
    width: 65px;
  }

  .indicator .nav-pills .nav-link {
    font-size: 9px;
  }

  /* .LeftColumn {
    top: 235px;
  } */

  .RightColumn {
    padding-left: 60px;
  }

  .product-sample {
    width: 100% !important;
    height: 100% !important;
  }


}

/* XL */



/****************************/
@media (min-width: 1200px) {

  .RightColumn li:nth-last-of-type(3) {
    margin-top: -5px;
  }

  .step4 li:last-of-type {
    margin-top: 0 !important;
  }

  .RightColumn li:nth-last-of-type(2) {
    margin-top: -5px;
  }

  .StyledElement {
    padding: 20px 20px 0 80px;
  }

  .LeftColumn {
    top: 240px;
  }

  .indicator {
    width: 90px;
    left: -20px;
  }

  .icon-button-badge {
    margin-left: -8px;
    margin-top: -9px;
  }

  .box-space {
    padding: 40% 0;
  }

  /* .card-img-top {
    height: 188px !important;
  } */

  /* .back .card-body {
    height: 316px;
  }

  .Itemcard-body {
    height: 135px;
  }

  .front .card-footer {
    height: 175px;
  } */

  .RightColumn {
    height: 113vh !important;
  }

  .cart-placeorder .modal-content {
    /* height: 250px;
    padding: 2em 3em; */
    height: fit-content;
    padding: 2em 3em 5em;
  }

  .cart-placeorder .modal-content .close {
    top: -4%;
  }

  .delete-Item .modal-content .close {
    top: -4%;
  }

  .delete-Item .modal-content {
    height: 245px;
    padding: 2em 3em;
  }

  /* .col-cart {
    padding-top: 65px;
  } */

  .card-footer:last-child {
    border-radius: 0 0calc(.25rem - 1px) calc(.25rem - 1px) !important;
  }

  .mh-step3 {
    /* min-height: 480px; */
    max-height: 1440px;
  }

  .RightColumn li:last-of-type {
    margin-top: -5px;
  }

  .btn-skip {
    width: auto;
    min-width: 170px;
  }

  .popup-changepw .modal-content {
    height: 610px;
    padding: 2em;
  }

  .section-3 {
    /* background: url(../img/bg-early-bird.jpg) no-repeat; */
    /* background: url(../img/water.jpg) no-repeat; */
    /* background-attachment: fixed;
    background-position: center top;
    background-size: cover; */
    background-color: white;
  }

  .nav-pills .nav-link {
    font-size: 12px;
  }



}

@media (max-width: 1199.98px) {



  .step4 li:last-of-type {
    margin-top: 0 !important;
  }

  .RightColumn li:nth-last-of-type(3) {
    margin-top: -5px;
  }

  .RightColumn li:nth-last-of-type(2) {
    margin-top: -5px;
  }

  /* .page {
    padding-top: 65px;
  } */

  .StyledElement {
    padding: 20px 20px 0 20px;
  }

  .RightColumn {
    padding-left: 50px;
  }

  .icon-button-badge {
    margin-left: -8px;
    margin-top: -9px;
  }

  .box-space {
    padding: 45% 0;
  }

  .mh-step3 {
    min-height: auto;
    max-height: 1440px;
  }

  .RightColumn li:last-of-type {
    margin-top: -5px;
  }

  .LeftColumn {
    top: 215px;
  }

  .card-img-top {
    height: 100%;
  }

  .step3 .card-img-top {
    height: 128px !important;
  }

  .back .card-body {
    height: 387px;
  }

  .Itemcard-body {
    height: 149px;
  }

  .step-3 .align-items-stretch {
    height: 0% !important;
  }

  .step-4 .align-items-stretch {
    height: 0% !important;
  }

  .align-items-stretch {
    height: 490px;
  }

  .front,
  .back {
    height: 488px;
  }

  .front .card-footer {
    height: 150px;
  }

  .RightColumn {
    height: 60vh;
  }

  .cart-placeorder .modal-content .close {
    top: -3%;
  }

  .cart-placeorder .modal-content {
    /* height: 250px; */
    height: fit-content;
    padding: 2em 2em 5em;
  }

  .delete-Item .modal-content .close {
    top: -6%;
  }

  .delete-Item .modal-content {
    height: 250px;
  }

  .Itemcard-footer .font-24 {
    font-size: 22px !important;
  }

  .btn-skip {
    width: auto;
    min-width: 170px;
  }

  .popup-changepw .modal-content {
    height: 610px;
    padding: 2em;
  }

  .section-3 {
    /* background: url(../img/bg-early-bird.jpg) no-repeat; */
    background-attachment: inherit;
    background-position: center;
    background-size: cover;
  }

  .nav-pills .nav-link {
    font-size: 12px;
  }

  .indicator {
    left: -15px;
    width: 80px;
  }

  .gustloginGG .textloginsocial {
    padding: 0px 28%;
  }

  .gustloginFB .textloginsocial {
    padding: 0 24%;
  }

  .landingpage .align-items-stretch {
    height: 550px;
  }

  .extra-landingpage .align-items-stretch {
    height: 410px;
  }

}

@media (min-width: 992px) {
  .popup-announce .modal-content {
    max-width: 850px;
    width: 850px;
  }

  .popup-announce .modal-dialog {
    left: -10%;
  }

  .popup-announce .modal-content {
    height: 55vh;
  }


  .step-3 .align-items-stretch {
    height: 450px !important;
  }

  .product-sample {
    width: 46%;
    height: 225px;
  }

  /* .page {
    padding-top: 65px;
  } */

  .icon-button-badge {
    margin-left: -8px;
    margin-top: -9px;
  }

  /* .card-img-top {
    height: 128px !important;
  } */

  .front .card-footer {
    height: 176px !important;
  }

  /* .RightColumn {
    height: 124vh;
  } */
}

@media (max-width: 991.98px) {

  .btn-done {
    min-width: 60% !important;
  }

  .headsubticket {
    height: 0px;
  }

  .page {
    padding-top: 52px;
  }

  /* 
  .col-cart {
    padding-top: 50px;
  } */

  .display-none {
    display: block;
  }

  .display-block {
    display: none;
  }

  .scrollable {
    padding: 20px 20px 0 20px;
    height: 68vh;
  }

  .indicator .nav-pills .nav-link.active {
    color: #77e6ff;
    font-weight: 900;
    border-bottom: 2px solid;
  }

  .btn-not-full {
    width: 100%;
  }

  .btn-done {
    min-width: 50%;
  }

  .slick-next {

    right: -25px !important;
  }

  /* .slick-prev {
    left: -5px !important;
    right: 5px!important;
  } */

  .slick-prev {
    left: -25px !important;
  }

  .navbar-collapse {
    background: #fefefe;
  }

  .d-block {
    /* text-align: center; */
  }

  .box-qrcode {
    min-height: 76vh;
    margin-top: -4px;
  }

  .popupterm .modal-dialog {
    max-width: 85%;
  }

  #logosignin {
    width: 35%;
  }

  .fWohKN {
    bottom: 115px !important;
  }

  .companymenuli:hover>ul {
    width: 12em;
  }

  .paymen-text {
    text-align: left;
  }

  .sticky-top {
    top: 51px;
  }

  #progressbar {
    width: 95%;
  }

  .sticky-top-Item {
    top: 120px;
  }

  .box-dec-step {
    width: 100%;
    top: 161px;
  }

  .LeftColumn {
    top: 210px;
  }

  .StyledElement {
    padding: 60px 20px 0 80px;
  }

  .product-sample {
    width: 45%;
    height: 225px;
  }

  .RightColumn {
    padding-left: 0;
    height: 120vh !important;
  }

  .icon-button-badge {
    margin-left: -8px;
    margin-top: -25px;
  }

  .card-img-top {
    height: 188px;
  }

  /* .step-3 .align-items-stretch {
    height: 560px;
  } */

  .align-items-stretch {
    /* height: 490px; old  */
    height: 620px;
  }

  .step4 .align-items-stretch {
    /* height: 490px; old  */
    height: 0% !important;
  }

  .front,
  .back {
    height: 100%;
  }

  .Itemcard-body {
    height: 149px;
  }

  .back .card-body {
    height: 387px;
  }

  .front .card-footer {
    height: 176px !important;
  }

  .delete-Item .modal-content .close {
    top: -3%;
  }

  .delete-Item .modal-content {
    height: 265px;
  }

  .cart-placeorder .modal-content .close {
    top: -3%;
  }

  .cart-placeorder .modal-content {
    /* height: 250px; */
    height: fit-content;
    padding: 2em 2em 5em;
  }

  .mh-step3 {
    min-height: auto;
    max-height: fit-content;
  }

  .RightColumn li:nth-last-of-type(2) {
    margin-top: 0;
  }

  .RightColumn li:nth-last-of-type(3) {
    margin-top: -21px;
  }

  .step4 li:last-of-type {
    margin-top: 0 !important;
  }

  .RightColumn li:last-of-type {
    margin-top: -21px;
  }

  .indicator li:not(:last-child) {
    margin-bottom: 35px;
  }

  .popup-changepw .modal-content {
    height: 610px;
    padding: 2em;
  }

  .section-3 {
    background-position: center;
    background-size: cover;
    background-attachment: inherit;
  }

  .nav-pills .nav-link {
    font-size: 12px;
  }

  .indicator {
    left: -15px;
    width: 80px;
  }

  .gustloginFB .textloginsocial {
    padding: 0 38%;
  }

  .gustloginGG .textloginsocial {
    padding: 0px 39%;
  }

  .box-space {
    padding: 65% 0;
  }

  .landingpage .align-items-stretch {
    height: 431px !important;
  }

  .landingpage-small .align-items-stretch {
    height: 390px !important;
  }

  .extra-landingpage .align-items-stretch {
    height: 450px;
  }

}

@media (min-width: 768px) {
  .landingpage-small {
    height: 340px !important;
  }

  /* .icon-button-badge {
    margin-left: -8px;
    margin-top: -9px;
  } */

  /* .page {
    padding-top: 50px;
  } */

}

@media (max-width: 767.98px) {
  .done table tr td span {
    font-size: 13px !important;
  }


  .done table tr td div {
    font-size: 13px !important;
  }


  .RightColumn li:nth-last-of-type(3) {
    margin-top: 0;
  }

  .step4 li:last-of-type {
    margin-top: 0 !important;
  }

  .StyledElement {
    padding: 135px 20px 0 20px !important;
  }

  .icon-button-badge {
    margin-left: -5px;
    margin-top: -22px;
    font-size: 10px;
    width: 15px;
    height: 15px;
  }

  .page {
    padding-top: 50px;
  }

  .box-dec-step {
    top: 120px;
  }

  .LeftColumn {
    top: 167px;
    width: 100%;
  }

  .card-img-top {
    height: 250px;
  }

  .RightColumn {
    height: 120vh !important;
  }

  .card-img-top {
    height: 188px;
  }

  .front .card-footer {
    height: 176px !important;
  }

  .cart-placeorder .modal-content .close {
    top: -6%;
  }

  .cart-placeorder .modal-content {
    /* height: 180px; */
    height: fit-content;
    padding: 2em 2em 5em;
  }

  .delete-Item .modal-content .close {
    top: -6%;
  }

  .delete-Item .modal-content {
    height: 250px;
  }

  .cart-mobile {
    display: block;
    padding-top: 50px
  }

  .front,
  .back {
    height: 100%;
  }

  .align-items-stretch {
    /* height: 525px; */
    height: 995px;
  }

  /* .step-3 .align-items-stretch {
    height: 900px;
  } */

  .back .card-body {
    height: 415px;
  }

  .Itemcard-body {
    height: 158px;
  }

  .mh-step3 {
    min-height: auto;
    max-height: fit-content;
  }

  .RightColumn li:last-of-type {
    margin-top: -21px;
  }

  .RightColumn li:nth-last-of-type(2) {
    margin-top: -21px;
  }

  .box-space {
    padding: 30% 0;
  }

  .indicator li:not(:last-child) {
    margin-bottom: 0;
  }

  .popup-changepw .modal-content {
    height: 610px;
    padding: 2em;
  }

  .section-3 {
    background-position: center;
    background-size: cover;
    background-attachment: inherit;
  }

  .indicator {
    left: 0;
    width: 100%;
  }

  .gustloginFB .textloginsocial {
    padding: 0 36%;
  }

  .gustloginGG .textloginsocial {
    padding: 0px 37%;
  }

  .landingpage .align-items-stretch {
    height: 874px !important;
    border: 0px solid rgb(224, 224, 224) !important;
  }

  .extra-landingpage .align-items-stretch {
    height: 450px;
  }

}

@media (min-width: 576px) {
  /* .page {
    padding-top: 50px;
  } */

  /* .LeftColumn {
    top: 169px;
  } */

  /* .icon-button-badge {
    margin-left: -8px;
    margin-top: -9px;
  } */

  /* .RightColumn {
    height: 120vh !important;
  } */

}

@media (max-width: 575.98px) {
  .payment-2c2p {
    width: 100%;
    /* margin-top: -1em; */
  }

  .box-checkpayment {
    padding: 0 0 0 1.5em;
  }

  .indicator li {
    width: 25%;
  }

  .display-none {
    display: block;
  }

  .display-block {
    display: none;
  }

  .ItemGroup {
    height: 3.7em;
  }

  .btn-not-full {
    width: 100%;
  }

  .btn-blue {
    background: #77e6ff;
    color: #0e315c !important;
  }

  .btn-blue:hover {
    color: #ffffff !important;
  }

  .page {
    padding-top: 50px;
  }

  .page-steps {
    padding-bottom: 0;
  }

  .slick-next {
    right: -20px !important;
  }

  .slick-prev {
    left: -20px !important;
  }

  .back .card-body {
    height: 301px;
  }

  .card-payment {
    height: 80vh;
  }

  .paymen-text {
    text-align: left;
  }

  .modal-content {
    padding: 2em;
    height: 60vh;
    width: 95%;
  }

  .popup-setpw .modal-content,
  .popup-changepw .modal-content,
  .popup-forget .modal-content {
    width: 95%;
  }

  .cart-placeorder .modal-content {
    /* height: 180px; */
    height: fit-content;
    padding: 2em 2em 5em;
  }

  .cart-placeorder .modal-content .close {
    top: -3%;
  }

  .cart-placeorder .modal-content .close-payment {
    top: -29%;
  }

  .delete-Item .modal-content .close {
    top: -3%;
  }

  .delete-Item .modal-content {
    height: 250px;
  }

  .cart-savecart .modal-content {
    height: 515px;
  }

  .popup-changepw .modal-content {
    height: 538px;
  }

  .popup-setpw .modal-content,
  .popup-forget .modal-content {
    height: 700px;
  }

  .popupterm .modal-dialog {
    max-width: 100%;
  }

  #logosignin {
    width: 50%;
  }

  .img-popup {
    height: 225px;
  }

  .logo-popup {
    width: 50%;
  }

  .img-forgetpopup {
    width: 68%;
  }

  .fWohKN {
    bottom: 115px !important;
  }

  .sticky-top {
    top: 50px;
  }

  .LeftColumn {
    top: 167px;
  }

  .icon-button-badge {
    margin-left: -5px;
    margin-top: -21px;
  }

  .RightColumn {
    height: 120vh !important;
  }

  .back .card-body {
    height: 395px;
  }

  .Itemcard-body {
    height: 140px;
  }

  .front .card-footer {
    height: 176px !important;
  }

  .front,
  .back {
    height: 100%;
  }

  .align-items-stretch {
    height: 825px;
  }

  /* .step-3 .align-items-stretch {
    height: 0% !important;
  } */

  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 2px) calc(0.25rem - 1px);
  }

  .RightColumn li:last-of-type {
    margin-top: -18px;
  }

  .RightColumn li:nth-last-of-type(2) {
    margin-top: -12px;
  }

  .popup-changepw .modal-content {
    height: 645px;
    padding: 2em;
  }

  .section-3 {
    background-position: center;
    background-size: cover;
    background-attachment: inherit;
  }

  .indicator {
    left: 0;
    width: 100%;
  }

  .gustloginFB .textloginsocial {
    padding: 0 25%;
  }

  .gustloginGG .textloginsocial {
    padding: 0px 28%;
  }

  .landingpage .align-items-stretch {
    height: 703px !important;
  }

  .landingpage-small .align-items-stretch {
    height: 372px !important;
  }

  .extra-landingpage .align-items-stretch {
    height: 408px;
  }

  .box-space {
    padding: 50% 0;
  }

  .card-hottest-left {
    height: 50%;
    width: 100% !important;
  }

}

@media (max-width: 575.98px) {
  .landingpage .align-items-stretch {
    height: 625px !important;
  }


  @media (max-width:440px) {
    .align-items-stretch {
      height: 675px !important;
    }
  }


}


/****************************/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'DB Helvethaica X 65 Med';
    src: url('./../fonts/DBHelvethaicaX-65Med.woff2') format('woff2'),
        url('./../fonts/DBHelvethaicaX-65Med.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


#content {
    background: url('./../component/img/songkran/bottom-bg.jpg');
    background-size: cover;
    z-index: 2;
    position: relative;
    margin-top: -2px;
    color: #000;
}

#content p {
    font-size: 30px;
    font-family: 'DB Helvethaica X 65 Med';
    line-height: 1.2;

}


.palm-leaves1 {
    margin-top: -20vh;
    position: absolute;
    right: 0;
}

.palm-leaves2 {
    margin-top: -50vh;
    position: absolute;
    left: 0;

}

.palm-leaves3 {
    bottom: 0;
    position: absolute;
    right: 0;
}

.floating-cta {
    position: fixed;
    right: 0;
    top: 30vh;
    z-index: 999;

}

.btn-mini{
    max-width:auto;	
    }


@media (max-width: 768px) {

    .floating-cta-mobile {
        position: fixed;
        bottom: 0;
        z-index: 999;

    }

    #content p {
        font-size: 20px;
        font-family: 'DB Helvethaica X 65 Med';
        line-height: 1.2;

    }

    .palm-leaves1 {
        margin-top: 25vh;
        position: absolute;
        right: 0;
        max-width: 25vw;
    }

    .palm-leaves2 {
        margin-top: -3vh;
        position: absolute;
        left: 0;
        max-width: 25vw;

    }

    .palm-leaves3 {
        bottom: 0;
        position: absolute;
        right: 0;
        max-width: 25vw;

    }

    .palm-leaves4 {
        margin-top: -29vw;
        position: absolute;
        left: 0;
        max-width: 25vw;
        padding: 0px;

    }

    
.btn-mini{
    max-width:50vw;	
    }



}
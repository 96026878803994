@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

/* .product-sample{
     margin-top: -120px;
    position: absolute;
    top: 20px;
} */

/* .card-title{
	font-size: 20px;
} */

.react-card-flip{
  height: 100%;
  background: #ffffff;
}


.banner-video{
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  padding-top: 0px !important;
  background-position: center bottom !important;
  background-size: cover !important;
}

.btn-darkblue-video {
  background: #ffffff;
  color: #132D4E;
  text-transform: none;
  font-weight: 700;
}


.btn-slider-video {
  background: #001d41;
  color: #fff;
  width: 220px;
  /* position: absolute;
    bottom: 23px; */
  right: 103px;
  line-height: 33px;
  padding: 0;
}

.btn-slider-video:hover {
  color: #77e6ff;
  background: #071c36;
}

.button-linkf-video{
  display: flex;
  position: absolute;
  top: 88%;
  left: 71%;
}

.button-links-video{
  display: flex;
  position:absolute;
  top: 88%;
  left: 71%;
}


.packagesave-banner{
  position: absolute;
  top: 52%;
  left: 60%;
}

.price-banner{
  font-size: 80px;
  margin-bottom:0px;
  color:#001D41;
  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  text-shadow: -1px 1px 0 #fff,
                          1px 1px 0 #fff,
                         1px -1px 0 #fff,
                        -1px -1px 0 #fff;
}

.banner-pricename{
  font-size: 25px;
  margin-bottom:0px;
  color:#001D41;
  text-shadow: -1px 1px 0 #fff,
                          1px 1px 0 #fff,
                         1px -1px 0 #fff,
                        -1px -1px 0 #fff;
}

.banner-discount{
  text-align: end;
  text-decoration-line: line-through;
  color:#FF7A00;
  text-shadow: 0px 0px 0 #fff, 0px 0px 0 #fff, 0px -1px 0 #fff, -1px -1px 0 #fff
}

.banner-pax{
  font-size:15px;
  color:#001D41;
  text-shadow: -1px 1px 0 #fff,
                          1px 1px 0 #fff,
                         1px -1px 0 #fff,
                        -1px -1px 0 #fff;
}

.banner-save{
  color:#fff;
}




.area-detail {
  margin-top: 0px;
  flex-flow: column wrap;
  height: 6em;
  display: flex;
}

.text-detail {
  width: 100%;
  align-items: center;
  color: rgb(122, 122, 122);
  font-weight: 500;
  font-size: 13px;
  padding-left: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-border {
  border: 1px solid #e0e0e0 !important;
}

.border-bottom-dot {
  border-bottom: 1px dotted #00000080;
  font-size: 19px;
  padding-bottom: 10px;
  /* margin-top: 25px; */
}

.peradult {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.card-hottest {
  display: contents;
  margin-bottom: 15px;
  height: 100%;
  min-height: 630px;
}

.card-hottest-left {
  height: 100%;
  width: 50%;
  /* max-height: 450px;
    min-height: 450px; */
}

.card-hottest .card-img-top {
  height: 100% !important;
  object-fit: fill;
  /* max-height: 256px; */
}

.save {
  color: #fff;
  background: #000;
  line-height: 1;
  padding: 3px 15px;
  margin-top: 6px;
  margin-bottom: 6px;
  width: 99%;
  height: 60px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 25px;
  background: #004ea9;
}

.section-3 .hottest-wrapper {
  width: 100%;
}

.hottest-wrapper {
  margin: 0 auto;
  width: 100%;
}

.section-hottest {
  /* background: url(../component/img/water.jpg) no-repeat; */
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}

.wrapper {
  position: relative;
}

.div-button {
  width: 220px;
}

.slider-text {
  position: absolute;
  left: 5vw;
  top: 50%;
  width: 350px;
  transform: translateY(-50%);
}

.slick-prev {
  left: -25px;
}

.slick-next {
  right: -25px;
}

.btn-nf {
  width: auto;
  min-width: 170px;
}

.adults {
  background-color: #000000;
  color: #fff;
  padding: 0.3rem 0.8rem 0.4rem;
  border-radius: 20px;
  margin: 0 1rem;
}

#adults {
  margin-top: -35px;
}

.slidervideo .slick-prev {
  left: 7px !important;
}

.slidervideo .slick-next {
  right: 7px !important;
}

.card-hottest .card-title {
  height: 100%;
  /* max-height: 60px;
    min-height: 60px; */
}

#Myvdo {
  width: 100%;
  height: 570px;
  object-fit: cover;
  display: flex;
  background-repeat: no-repeat;
  background-position: top center;
}

#Mybanner {
  width: 100%;
  height: 570px;
  object-fit: cover;
  display: flex;
  background: url(http://ohhsome.agency/buytickets/img/bg-slider.jpg) no-repeat;
  background-repeat: no-repeat;
  background-position: top center;
}

.banner {
  /* position: absolute; */
  top: 0;
  height: 100%;
  height: 542px ;
  margin: 0 auto;
  width: 100%;
  padding-top: 0px !important;
  background-position: center bottom !important;
  background-size: cover !important;
}

.banner-left{
  position: relative !important;
  width: 40% ;
  height: 100% !important;
}


.banner-right{
  position: relative !important;
  width: 60% ;
  height: 100% !important;
}

.surfboy{
  width: 79%;
  position: absolute;
  left: 0%;
}

.save-banner{
  width: 35%;
    top: 55%;
    left: 55%;
    position: absolute;
}

.btn-darkblue {
  background: #001d41;
  color: #3cdbff;
  text-transform: none;
  font-weight: 700;
}

.btn-darkblue:hover {
  color: #3cdbff;
}

.btn-slider {
  background: #001d41;
  color: #fff;
  width: 220px;
  /* position: absolute;
    bottom: 23px; */
  right: 103px;
  line-height: 33px;
  padding: 0;
}

.btn-slider:hover {
  color: #77e6ff;
  background: #071c36;
}

.button-linkf {
  position: absolute;
  bottom: 5%;
}

.button-links {
  position: absolute;
  bottom: 5.5%;
  left: 72%;
}

@media (min-width: 1500px) {
  .section-3 .hottest-wrapper {
    width: 100%;
  }

  .btn-slider {
    /* width: 250px; */
    height: 38px !important;
    line-height: 15px;
    font-size: 16px;
  }

  .btn-darkblue {
    font-size: 16px;
    bottom: 28%;
    width: 374px !important;
    height: 40px !important;
  }

  .btn-slider-video {
    width: 450px !important;
    height: 66px !important;
    line-height: 33px;
    font-size: 26px;
  }

  .btn-darkblue-video {
    font-size: 20px;
    bottom: 28%;
    /* left: 11% !; */
    width: 500px !important;
    height: 45px !important;
  }

  .btn-slider-video {
    width: 255px !important;
    height: 42px !important;
    font-size: 20px !important;
  }
  /* .button-linkf{
        height: auto !important;
    } */

  /* .button-links {
    height: auto !important;
    left: 73% !important;
    bottom: 6% !important;
  } */

  .button-links {
    height: auto !important;
    left: 56% !important;
    bottom: 6% !important;
  }

 
}

@media (min-width: 1200px) {
  .section-3 .hottest-wrapper {
    width: 100%;
  }

  .setwidthsecond{
    top: 40%!important;
  }
  .button-linkf {
    bottom: 39%;
    width: 405px;
    right: 0%;
  }
  .button-links {
    /* height: 22%; */
    left: 55%;
    bottom: 5% !important;
  }

  .btn-darkblue {
    font-size: 16px;
    bottom: 28%;
    width: 374px !important;
    height: 40px !important;
  }

  .btn-darkblue-video {
    width: 400px !important;
    height: 42px !important;
  }

  /* .btn-slider-video {
    width: 180px !important;
  } */

  .button-linkf-video{
    display: flex;
    position: absolute;
    top: 64%;
    left: 10%;
  }
  
  .button-links-video{
    display: flex;
    position:absolute;
    top: 88%;
    left: 71%;
  }
  
}

@media (max-width: 1199.98px) {
  .card-hottest .card-img-top {
    height: 100%;
  }

  .section-3 .hottest-wrapper {
    width: 90%;
  }

  .slider-text .big-title {
    font-size: 70px;
  }

  #Myvdo,
  #Mybanner {
    height: 450px;
  }
  .button-linkf {
    bottom: 40% !important;
    width: 92%;
    left: 2%;
    /* height: 22%; */
  }
  .btn-slider {
    width: 226px !important;
    height: 44px !important;
    font-size: 16px !important;
    line-height: 14px !important;
  }
.button-links{
    left: 60% !important;
    bottom: 5% !important;
  }

  .banner{
    display: block !important;
    /* height: 795px !important; */
    height: 1040px  !important;
  }

  .banner-loading{
    display: flex !important;
    /* height: 795px !important; */
    height: 850px  !important;
  }

  .surfboy{
    width: 78%;
    position: absolute;
    left: 7%;
  }

  .setwidthfirst{
    top: 13% !important;
    width: 98% !important;
  }

  .setwidthsecond{
    width: 98% !important;
    top: 39% !important;
    right: 0% !important;
  }

 .setwidth-last{
    top: 84% !important;
    width: 98% !important;
    right: 0% !important;
  }

  .setwidth{
    width: 98% !important;
  }

  .setwidth-last{
    top: 77% !important;
    width: 98% !important;
  }

  .banner-left{
    position: relative !important;
    width: 100% !important;
    height: 39% !important;
  }
  
  
  .banner-right{
    position: relative !important;
    width: 100% !important;
    height: 50% !important;
  }

  .pre-open{
    font-size: 38px !important;
  }

  

  .btn-darkblue {
    height: 48px !important;
  }

  .button-linkf-video {
    display: flex;
    position: absolute;
    top: 35%;
    left: 8%;
  }

   .btn-darkblue-video {
    width: 640px !important;
    height: 65px !important;
    font-size: 25px !important;
  }

  .button-links-video {
    display: flex;
    position: absolute;
    top: 87%;
    left: 58%;
}

  .btn-slider-video {
    width: 233px !important;
    height: 40px !important;
  }

  .setwidthPic{
    top: 3%!important;
    width: 60% !important;
    right: unset!important;
  }

  .img-banner{
    width: 100% !important;
  }

}

@media (max-width: 991.98px) {
  .card-hottest .card-img-top {
    height: 100%;
    /* min-height: 200px;
        max-height: 200px; */
  }

  .section-3 .hottest-wrapper {
    width: 90%;
  }

  .card-hottest {
    height: 580px !important;
    display: contents !important;
  }

  .card-hottest-large {
    height: 100%;
    min-height: 840px !important;
    /* max-height: 530px;
        min-height: 530px; */
  }

  .banner{
    display: block !important;
    height: 890px !important;
  }

  .landingpage-small {
    height: 410px !important;
    width: 100% !important;
}

  .slider-text .big-title {
    font-size: 50px;
  }

  .div-button {
    margin: auto;
  }

  #Myvdo,
  #Mybanner {
    height: 450px;
  }

  .section-hottest {
    background-position: left bottom;
    background-attachment: initial;
    object-fit: cover;
    width: 100%;
  }

  .button-linkf {
    /* height: 10%; */
  }

  .setwidthsecond{
    width: 98% !important;
    top: 40% !important;
    right: 0% !important;
  }

 .setwidth-last{
    top: 66% !important;
    width: 98% !important;
    right: 0% !important;
  }


  .save {
    font-size: 18px !important;
  }

  .border-bottom-dot {
    font-size: 18px !important;
  }

  .text-center {
    left: 10px !important;
  }

  .btn-darkblue {
    width: 100%!important;
    height: 47px !important;
    font-size: 18px;
  }
  .btn-slider {
    width: 160px !important;
    height: 32px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }
.button-links{
    left: 61% !important;
    bottom: 5% !important;
  }
  .setwidthPic{
    top: 3%!important;
    width: 65% !important;
    right: unset!important;
  }
}

@media (max-width: 767.98px) {
  /* .big-title.main-title {
        font-size: 20px;
    } */
.surfboy {
    width: 85% !important;
    bottom: 1%;
    }

  .slick-prev {
    left: 15px;
  }

  .slick-next {
    right: 15px;
  }

  .btn.btn-slide {
    font-size: 9px;
    font-weight: 800;
    height: 30px;
    padding: 5px;
  }

  .slider-text {
    position: absolute;
    left: 0;
    top: 10%;
    width: 45vw;
    transform: none;
    padding: 20px 25px 20px;
    text-align: center;
  }

  .div-button {
    width: 22vw;
    margin: auto;
    margin-top: -5px;
  }

  .slider-text .big-title {
    font-size: 40px;
  }

  .section-3 .hottest-wrapper {
    width: 90%;
  }

  .border-bottom-dot {
    font-size: 18px;
  }

  .card-hottest {
    height: 100%;
    display: inherit !important;
    min-height: 1030px !important;
    /* max-height: 450px;
        min-height: 450px; */
  }

  .card-hottest-large {
    display: flex !important;
    height: 100%;
    min-height: 845px !important;
    /* max-height: 530px;
        min-height: 530px; */
  }

  .card-hottest-left {
    height: 100%;
    width: 100% !important;
    /* max-height: 450px;
        min-height: 450px; */
  }

  .card-hottest-right {
    height: 100%;
    width: 100% !important;
    /* max-height: 450px;
        min-height: 450px; */
  }

  .landingpage-small {
    height: 1045px !important;
    width: 100% !important;
  }

  .landingpage {
    height: 900px !important;
  }

  #Myvdo,
  #Mybanner {
    height: 290px;
  }

  .button-linkf {
    /* height: 7%; */
  }

  .save {
    font-size: 20px;
  }
  .text-detail {
    font-size: 14px !important;
  }
  .area-detail {
    height: 6.5em;
  }
  .btn-darkblue {
    width: 100%!important;
    height: 47px !important;
    font-size: 15px;
  }
  .btn-slider {
    width: 135px !important;
    height: 28px !important;
    font-size: 7px !important;
    line-height: 14px !important;
  }
.button-links{
    left: 61% !important;
    bottom: 5% !important;
  }

  .price-banner{
    font-size: 70px;
  }
  
  .banner-pricename{
    font-size: 13px;
  }
  
  .banner-discount{
    font-size: 18px;
  }
  
  .banner-pax{
    font-size:5px;
  }
  
  .banner-save{
    font-size: 28px;
  }

  .btn-darkblue-video {
    width: 520px !important;
    height: 55px !important;
    font-size: 25px !important;
}

.setwidthPic{
  top: 3%!important;
  width: 75% !important;
  right: unset!important;
}


}

@media (max-width: 676px) {
  .btn-darkblue {
    width: 100% !important;
    height: 47px !important;
    font-size: 18px;
  }
  .btn-slider {
    width: 130px !important;
    height: 26px !important;
    font-size: 7px !important;
    line-height: 14px !important;
  }
.button-links{
    left: 58% !important;
    bottom: 5% !important;
  }
  .price-banner{
    font-size: 60px;
  }
  
  .banner-pricename{
    font-size: 10px;
  }
  
  .banner-discount{
    font-size: 14px;
  }
  
  .banner-pax{
    font-size:5px;
  }
  
  .banner-save{
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .btn-darkblue {
    width: 100% !important;
    height: 36px !important;
    font-size: 15px;
  }
  .btn-slider {
    width: 130px !important;
    height: 26px !important;
    font-size: 7px !important;
    line-height: 14px !important;
  }
.button-links{
    left: 58% !important;
    bottom: 5% !important;
  }
  .price-banner{
    font-size: 55px;
  }
  
  .banner-pricename{
    font-size: 10px;
  }
  
  .banner-discount{
    font-size: 14px;
  }
  
  .banner-pax{
    font-size:5px;
  }
  
  .banner-save{
    font-size: 15px;
  }

  .surfboy {
    width: 85% !important;
    bottom: 5%;
  }
}

@media (max-width: 575.98px) {
  .card-hottest .card-img-top {
    height: 100% !important;
    /* min-height: 256px;
        max-height: 256px; */
  }

  .section-3 .hottest-wrapper {
    width: 76%;
  }

  .card-hottest {
    height: 100% !important;
    min-height: 732px !important;
    /* max-height: 530px;
        min-height: 530px; */
  }

  .card-hottest-large {
    display: flex !important;
    height: 100%;
    min-height: 687px !important;
    /* max-height: 530px;
        min-height: 530px; */
  }

  .card-hottest .card-img-top {
    height: 100% !important;
  }

  .landingpage-small {
    height: 750px !important;
    width: 100% !important;
  }

  .landingpage {
    height: 720px !important;
  }

  .slider-text .big-title {
    font-size: 37px;
  }

  #Myvdo,
  #Mybanner {
    height: 185px;
  }
  .button-linkf {
    bottom: 55% !important;
    /* height: 3%; */
  }


  .text-detail {
    font-size: 16px !important;
  }

  .btn-darkblue {
    width: 100% !important;
    height: 33px !important;
    font-size: 13px;
    line-height: 0px !important;
  }

  .btn-slider {
    width: 87px !important;
    height: 14px !important;
    font-size: 7px !important;
    line-height: 14px !important;
  }

  /* .surfboy{
    width: 70%;
    position: absolute;
    left: 7%;
  } */

  .surfboy {
    width: 80% !important;
    bottom: 5%;
  }

  .banner{
    height: 632px !important;
  }

  .banner-left{
    height: 314px !important;
  }

  .banner-right {
    height: 312px !important;
  }

  .setwidth-last {
    top: 50% !important;
  }

  .setwidthsecond {
    top: 46% !important;
  }

  .pre-open{
    font-size: 30px !important;
  }
  
  .aque{
    font-size: 20px !important;
  }

  .valid{
    font-size: 26px !important;
  }
  .button-links{
    left: 58% !important;
    bottom: 5% !important;
  }
  /* .btn-slider {
    width: 80px !important;
    height: 17px !important;
    font-size: 8px;
    line-height: 16px !important;
  } */
  /* .area-detail{
        height: 8.5em;
    } */
    .price-banner{
      font-size: 32px;
    }
    
    .banner-pricename{
      font-size: 10px;
    }
    
    .banner-discount{
      font-size: 14px;
    }
    
    .banner-pax{
      font-size:5px;
    }
    
    .banner-save{
      font-size: 15px;
    }

    .button-linkf-video{
      display: flex;
      position: absolute;
      top: 34%;
      left: 8%;
    }

    .btn-darkblue-video {
      width: 300px !important;
      height: 35px !important;
      font-size: 14px !important;
      line-height: 0px;
    }
  
    .button-links-video{
      display: flex;
      position:absolute;
      top: 86%;
      left: 60%;
    }

    .btn-slider-video {
      width: 100px !important;
      height: 25px !important;
      font-size: 9px !important;
      line-height: 0px;
    }
  
}

@media (max-width: 440px) {
  .surfboy {
    width: 88% !important;
    bottom: 10%;
  }
}


@media (max-width: 400px) {

  .banner{
    height: 620px !important;
  }

  .banner-left{
    height: 292px !important;
  }

  .banner-right {
    height: 313px !important;
  }

  .setwidth-last {
    top: 47% !important;
  }

  .setwidthsecond {
    top: 47% !important;
  }

  .pre-open{
    font-size: 30px !important;
  }
  
  .aque{
    font-size: 20px !important;
  }

  .valid{
    font-size: 26px !important;
  }
  .button-linkf {
    bottom: 60% !important;
  }

  .button-links{
    left: 57% !important;
  }
  
  .btn-darkblue {
    width: 100% !important;
    height: 30px !important;
    font-size: 14px !important;
    line-height: 0px !important;
  }

  .btn-slider {
    width: 100px !important;
    height: 20px !important;
    font-size: 7px !important;
    line-height: 14px !important;
  }

  .surfboy {
    width: 85% !important;
    bottom: 5% !important;
  }
  .price-banner{
    font-size: 35px;
  }
  
  .banner-pricename{
    font-size: 8px;
  }
  
  .banner-discount{
    font-size: 8px;
  }
  
  .banner-pax{
    font-size:5px;
  }
  
  .banner-save{
    font-size: 13px;
    text-align: center;
  }

  .btn-slider-video {
    width: 100px !important;
    height: 25px !important;
    font-size: 9px !important;
    line-height: 0px;
  }

  .button-links-video{
    display: flex;
    position:absolute;
    top: 86%;
    left: 60%;
  }
}



@media (max-width: 385px) {

  .landingpage-small {
    height: 678px !important;
    width: 100% !important;
}

.card-hottest {
  height: 100% !important;
  min-height: 660px !important;
}

.card-hottest-left {
  height: 60% !important;
  width: 100% !important;
}

.card-hottest-large {
  display: flex !important;
  height: 100%;
  min-height: 620px !important;
}

.landingpage {
  height: 645px !important;
}

  .banner{
    height: 620px !important;
  }

  .banner-left{
    height: 292px !important;
  }

  .banner-right {
    height: 313px !important;
  }

  .setwidth-last {
    top: 55% !important;
  }

  .setwidthsecond {
    top: 48% !important;
  }

  .pre-open{
    font-size: 30px !important;
  }
  
  .aque{
    font-size: 20px !important;
  }

  .valid{
    font-size: 26px !important;
  }
  .button-linkf {
    bottom: 55% !important;
  }

  .button-links{
    left: 57% !important;
  }
  
  .btn-darkblue {
    width: 100% !important;
    height: 30px !important;
    font-size: 12px;
    line-height: 0px !important;
  }

  .btn-slider-video {
    width: 50px !important;
  }

  .btn-slider {
    width: 95px !important;
    height: 17px !important;
    font-size: 7px !important;
    line-height: 14px !important;
  }

  .surfboy {
    width: 88% !important;
    bottom: 5% !important;
  }

  .btn-slider-video {
    width: 100px !important;
    height: 25px !important;
    font-size: 9px !important;
    line-height: 0px;
  }

  .button-links-video{
    display: flex;
    position:absolute;
    top: 86%;
    left: 60%;
  }
}

